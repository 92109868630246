@import url('https://use.typekit.net/lwv8irh.css');

/**
Use a better box model (opinionated).
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'futura-pt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.25;
}

img {
  max-width: 100%;
  height: auto;
}
